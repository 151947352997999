const axios = require('axios').default;

/**
 *
 * @constructor Handlers
 */
export class Handlers {
    constructor() {
    }
    async excelExport(event) {
        event.preventDefault();
        let areaId = event.currentTarget.dataset.actionAreaId;
        let excelDataContainer = document.getElementById(areaId);
        let excelData = excelDataContainer.innerHTML;
        let formBody = [];
        let encodedValue = encodeURIComponent(excelData);
        formBody.push("data=" + encodedValue);
        formBody = formBody.join("&");

        fetch('/app/views/print_forms/excel.export.view.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formBody
        })
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                window.open(url, '_blank');
                URL.revokeObjectURL(url);
            });
    }
}


